import React, {
  ForwardedRef,
  useEffect,
  createRef,
  useRef,
  MouseEvent,
  ReactNode,
} from "react";
import classNames from "classnames";
import { IconChevronDown } from "@tabler/icons-react";
import { FormattedMessage } from "react-intl";

import "./ChatDetail.css";
import { Message, Interactive } from "../interfaces";
import {
  IonBadge,
  IonText,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonContent,
} from "@ionic/react";
import { Dropdown } from "react-bootstrap";
import InteractiveContent from "./common/interactive-content";
import ReplyContent from "./common/reply-content";
import PlaceholderReplacer from "./outbound/templates/placeholder";

interface ILayoutProps {
  contactId: String;
  messages: Message[];
  openImage: (link: string) => void;
  mode?: String;
  scroll?: Number;
  scrollEscalations?: Number;
  isEscalated?: Boolean;
  solveEscalations?: Function;
  type?: "playground" | "conversation";
  companySlug?: string;
  replyTo: Function;
}

const CustomToggle = React.forwardRef(
  (
    {
      children,
      onClick,
    }: {
      children: ReactNode;
      title: string;
      onClick: (e: MouseEvent<HTMLAnchorElement>) => void;
    },
    ref: ForwardedRef<HTMLAnchorElement>
  ) => (
    <a
      className="btn-visito-white btn-actions"
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <IconChevronDown size={14} />
    </a>
  )
);

const ChatDetail: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const contentRef = createRef<HTMLIonContentElement>();
  const bottomEl = useRef<HTMLDivElement>(null);
  const myRef = useRef<HTMLDivElement>(null);
  const {
    messages,
    openImage,
    contactId,
    mode,
    scroll,
    scrollEscalations,
    isEscalated,
    solveEscalations,
    type = "conversation",
    replyTo,
  } = props;

  function scrollToBottom(speed: number) {
    contentRef.current?.scrollToBottom(speed);
  }

  useEffect(() => {
    if (mode === "desktop") {
      setTimeout(() => {
        bottomEl?.current?.scrollIntoView();
      }, 100);
    }
    scrollToBottom(0);
  }, [contactId]);

  useEffect(() => {
    if (scroll) {
      scrollToBottom(0);
    }
  }, [scroll]);

  useEffect(() => {
    scrollToBottom(200);
  }, [messages.length]);

  useEffect(() => {
    if (scrollEscalations) {
      myRef?.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [scrollEscalations]);

  const scrollToMessage = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: "center", behavior: "smooth" });
      element.classList.add("highlight");
      setTimeout(() => {
        element.classList.remove("highlight");
      }, 1000);
    }
  };

  const messagesByDayGroup: { [key: string]: any[] } = {};

  // SHOULD WE MOVE THIS TO A useState function instead???
  messages.forEach((item) => {
    const { timestampGrouping, ...rest } = item;
    if (!messagesByDayGroup[timestampGrouping]) {
      messagesByDayGroup[timestampGrouping] = [];
    }
    messagesByDayGroup[timestampGrouping].push(rest);
  });
  // -----------------------------------------------------

  const interactiveActions = (interactive: Interactive) => {
    const { flag, data } = interactive;
    if (flag) {
      const buttons = data && data.buttons ? data.buttons : [];
      const lists = data && data.list ? data.list : [];
      if (lists.length) {
        return (
          <div className="chat-actions">
            <div className="chat-select-wrapper">
              <div className="chat-select-options">
                <IonSelect
                  aria-label="options"
                  placeholder="Options"
                  interface="popover"
                >
                  {lists[0].rows.map((item, i) => (
                    <IonSelectOption key={i} value={i}>
                      {item.title}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </div>
            </div>
          </div>
        );
      }
      if (buttons.length) {
        const content = buttons.map((button, i) => (
          <IonButton
            key={i}
            expand="block"
            color="light"
            size="small"
            mode="ios"
            disabled
          >
            {button.title}
          </IonButton>
        ));
        return <div className="chat-actions">{content}</div>;
      }
      return null;
    }
  };

  const messageContent = (content: string, interactive: Interactive) => {
    const { flag, data } = interactive;
    let body = "";
    let footer = "";
    if (flag) {
      body = (data && data.body) || content;
      footer = (data && data.footer) || "";
    } else {
      body = content;
    }
    const answer = [];

    answer.push(
      <PlaceholderReplacer template={body} placeholders={[]} key={1} />
    );
    if (footer) {
      answer.push(
        <IonText className="chat-footer" color="medium" key={2}>
          {footer}
        </IonText>
      );
    }

    return answer;
  };

  const isPlayground = type === "playground";

  return (
    <IonContent ref={contentRef}>
      <div
        className={`chat-detail ${
          isPlayground ? "h-100 chat-detail-playground" : ""
        }`}
      >
        <div
          className={`chat-detail-content ${isPlayground ? "h-100" : ""} ${
            mode === "ios" ? "px-2" : ""
          }`}
        >
          {Object.keys(messagesByDayGroup).map((key, i) => {
            let ref1 = false;
            if (i === Object.keys(messagesByDayGroup).length - 1) {
              ref1 = true;
            }
            return (
              <div key={key}>
                <div className="time-badge">
                  <IonBadge color="light">{key}</IonBadge>
                </div>
                {messagesByDayGroup[key].map((message: Message, index) => {
                  let ref = false;
                  if (index === messagesByDayGroup[key].length - 1) {
                    ref = true;
                  }
                  return (
                    <div
                      className={classNames("chat-wrapper relative", {
                        right: message.right,
                        escalated: message.escalated === true,
                      })}
                      {...(message.escalated ? { ref: myRef } : {})}
                      key={message.id || "new"}
                      id={message.id}
                    >
                      <div className="chat-holder">
                        <div
                          className={classNames("chat-item", {
                            interactive: message.interactive.flag === true,
                          })}
                        >
                          <div className="chat-item-options">
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-custom-components"
                                as={CustomToggle}
                              >
                                Custom toggle
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  eventKey="1"
                                  onClick={() => replyTo({ message })}
                                >
                                  <FormattedMessage id="chats.reply" />
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          {message.replyTo?.id && (
                            <div style={{ marginBottom: "0.5rem" }}>
                              <ReplyContent
                                message={message.replyTo}
                                right={message.right}
                                onAfterClick={(id: string) =>
                                  scrollToMessage(id)
                                }
                              />
                            </div>
                          )}
                          {message?.interactive?.flag &&
                            message?.interactive?.data?.header && (
                              <InteractiveContent
                                content={message.interactive.data}
                                mode={mode}
                                scrollToBottom={scrollToBottom}
                                openImage={openImage}
                                isSentMessage={message.right}
                              />
                            )}
                          <div className="chat-item-text">
                            <p>
                              {messageContent(
                                message.content,
                                message.interactive
                              )}
                              {type !== "playground" && (
                                <span className="chat-item-time">
                                  {message.isManual ? (
                                    <span className="color-step-500">
                                      <FormattedMessage id="chats.manual" /> -{" "}
                                    </span>
                                  ) : null}
                                  <span className="color-step-500">
                                    {message.timestamp}
                                  </span>
                                  {message.right ? (
                                    <svg
                                      viewBox="0 0 16 11"
                                      height="11"
                                      width="16"
                                      preserveAspectRatio="xMidYMid meet"
                                      className={classNames("", {
                                        read: message.status === "read",
                                        failed: message.status === "failed",
                                      })}
                                      fill="none"
                                    >
                                      <path
                                        d={message.svg}
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  ) : null}
                                </span>
                              )}
                            </p>
                          </div>
                        </div>
                        {message.error ? (
                          <div className="chat-item-error color-step-500">
                            {message.error}
                          </div>
                        ) : null}
                        {interactiveActions(message.interactive)}
                        {ref && ref1 && (
                          <div
                            key="scroller"
                            ref={bottomEl}
                            style={{
                              height: "0.2rem",
                            }}
                          ></div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {mode !== "desktop" && isEscalated && solveEscalations ? (
        <div className="escalation-button-holder">
          <button
            onClick={() => solveEscalations(contactId)}
            className="btn btn-visito-primary-border"
          >
            <FormattedMessage id="esc.solve" />
          </button>
        </div>
      ) : null}
    </IonContent>
  );
};

export default ChatDetail;
