const lang = {
  "common.and": "y",
  "common.or": "o",
  "common.loading": "Cargando",
  "common.done": "Listo",
  "common.close": "Cerrar",
  "common.continue": "Continuar",
  "common.accept": "Aceptar",
  "common.property": "Propiedad",
  "common.status": "Estado",
  "common.skip": "Saltar",
  "common.pause": "Pausar",
  "common.activate": "Activar",
  "common.back": "Atrás",
  "common.active": "Activo",
  "common.manage": "Gestionar",
  "common.setup": "Configurar",
  "common.connect": "Conectar",
  "common.configure": "Configurar",
  "common.call": "Llamada",
  "common.inactive": "Inactivo",
  "common.cancel": "Cancelar",
  "common.account": "Cuenta",
  "common.file": "Archivo",
  "common.photo": "Fotos",
  "common.runAi": "Correr conversacional",
  "common.remove": "Borrar",
  "common.reset": "Reestablecer",
  "common.confirmInactive": "Si, desactivar",
  "common.confirmContinue": "Si, continuar",
  "common.error": "Error",
  "common.tryItLater": "Hubo un error, inténtalo más tarde",
  "common.success": "Exitoso",
  "common.today": "Hoy",
  "common.week": "Esta semana",
  "common.7days": "Últimos 7 días",
  "common.30days": "Últimos 30 días",
  "common.thisMonth": "Este mes",
  "common.lastMonth": "Mes pasado",
  "common.custom": "Custom",
  "common.date": "Fecha",
  "common.name": "Nombre",
  "common.message": "Mensaje",
  "common.noResults": "No hay registros para el rango de fechas seleccionado",
  "common.results": "{total} resultados",
  "common.body": "Mensaje",
  "common.tags": "Etiquetas",
  "common.save": "Guardar",
  "common.saveClose": "Guardar y cerrar",
  "common.submitChanges": "Enviar cambios",
  "common.updating": "Actualizando...",
  "common.newConversation": "Nueva conversación",
  "common.create": "Crear",
  "common.phone": "Número de teléfono",
  "common.contactName": "Nombre del contacto",
  "common.optional": "opcional",
  "common.signUp": "Registrarse",
  "common.copyLink": "Link copiado",
  "common.share": "Compartir",
  "common.virtualAssistant": "Asistente Virtual",
  "common.scan": "Escanea tu sitio web",

  "settings.pageTitle": "Ajustes",
  "settings.pageDescription": "Ajusta tus preferencias",
  "settings.ai": "Ajustes de IA",
  "settings.accountTitle": "Cuenta",
  "settings.language": "Idioma",
  "settings.settingsTitle": "Ajustes",
  "settings.channels": "Canales",
  "settings.integrations": "Integraciones",
  "settings.teams": "Equipo",
  "settings.payment": "Facturación",
  "esc.needsReview": "Revisión",
  "esc.escalation": "Ayuda",
  "esc.clickToView": "Clica para ver",
  "esc.tapToView": "Toca para ver",
  "esc.solve": "Resolver la escalada",
  "nav.inbox": "Chats",
  "nav.integrations": "Integraciones",
  "nav.home": "Inicio",
  "nav.outbound": "Eventos",
  "nav.ai": "IA",
  "nav.crm": "CRM",
  "nav.settings": "Ajustes",
  "nav.logout": "Salir",
  "nav.trial":
    "Tu prueba gratuita termina en {count, plural, one {# día} other {# días}}",
  "nav.payment": "Tu prueba gratuita ha terminado.",
  "nav.paymentCTA": "Suscríbete hoy",
  "ai-settings.title": "Ajustes de IA",
  "ai-settings.paneTitle": "Configuración de Inteligencia Artificial",
  "ai-settings.businessInfo": "Información de la propiedad",
  "ai-settings.businessInfoDescription":
    "Proporciona información general sobre tu negocio.",
  "ai-settings.guestInfo": "Información solo para huéspedes",
  "ai-settings.guestInfoDescription":
    "Proporciona información específica para huéspedes con una reserva existente.",
  "ai-settings.notifications": "Notificaciones",
  "ai-settings.notificationsDescription":
    "Descarga la aplicación Visito y recibe notificaciones push para conversaciones no resueltas.",
  "ai-settings-intructions.title": "Ajustes",
  "ai.pageTitle": "Inteligencia Artificial",
  "ai.pageDescription": "Ajusta y experimenta con tu agente de IA",
  "ai.contextTitle": "Base de Conocimiento",
  "ai.contextDescription":
    "Gestiona tus conocimientos para entrenar a tu agente de IA",
  "ai.playgroundTitle": "Sandbox del Agente de IA",
  "ai.playgroundDescription":
    "Interactúa con tu agente de IA para observar cómo responde",
  aiSettingsWorking: "Estamos preparando tu información",
  aiSettingsOptimizing: "Estamos optimizando tu información",
  aiSettingsOptimizingDesc:
    "Estamos optimizando la información extraída de su sitio web",
  aiSettingsKnowledge: "Hub de IA",
  aiSettingsKnowledgeDesc:
    "Aquí puede proporcionar información para entrenar la IA. Pruebe la IA de la derecha para ver qué experimentarán sus clientes.",
  aiSettingsAlert:
    "Aquí puede proporcionar información para entrenar la IA. Pruebe la IA de la derecha para ver qué experimentarán sus clientes.",
  aiSettingsFreeText: "Texto libre",

  aiSettingsReset: "Limpiar",
  aiSettingsCall: "Llamar",
  aiSettingsCalling: "Llamando",
  aiSettingsCallConnected: "Conectado",

  aiSettingsHangup: "Colgar",
  aiSettingsAssitant: "¡Conoce a Lana! Tu asistente virtual",
  aiSettingsAssitantDesc:
    "Aquí es donde puede experimentar e interactuar con Lana para comprender exactamente qué experimentarán sus clientes con las respuestas de la IA.",
  aiCrawlingFailed:
    "Hubo un problema al analizar tu sitio web, inténtalo más tarde",
  aiCrawlingSkip: "¿Quieres continuar sin analizar tu sitio web?",
  aiCrawlingSkipDesc:
    "Analizar tu sitio web ayudará a tu asistente de IA a ofrecerte mejores resultados",
  aiSettingsSaving: "Guardando cambios...",
  aiExceededLength: "Excediste la longitud de caracteres",
  aiCompanyTitle: "{company} asistente virtual",
  aiCompanyWelcomeTitle: "Bienvenido al asistente virtual de {company}",
  aiCompanyFormDesc: "Introduce tu nombre para empezar",
  aiCompanyExplore: "Continuar",
  aiShare: "Comparte tu asistente virtual",
  aiCrawl: "¡Escaneemos su sitio web y entrenemos a su agente de IA!",
  aiCrawling: "Tu IA está rastreando el sitio web",
  aiCrawlingWebsiteSucc: "Análisis del sitio web completado",
  aiCrawlingSuccess: "Su sitio web se rastreo exitosamente",
  aiCrawlingSuccessDesc: "Su información esta lista para su revisión.",
  aiReview: "Revisémoslo",
  aiAgent: "Agente de IA",
  aiAgentCreated: "Tu agente de IA está listo",
  aiUpdateCta: "Editar",
  aiUpdateSucces: "Tu agente de IA esta actualizado",
  aiGeneralContext: "Información General",
  aiProviderContext: "Información del Proveedor",

  "account.successAdded":
    "La invitación ha sido aceptada y ahora tienes acceso a la cuenta.",
  "account.acceptInvitation.title": "Aceptar Invitaciones",
  "account.acceptInvitation.pageDescription":
    "Por favor, acepta tus invitaciones pendientes.",
  "account.acceptInvitation.account": "Nombre de la cuenta",
  "account.acceptInvitation.noPending":
    "No tienes niguna invitación pendiente...",

  "auth.login.title": "Bienvenido a Visito",
  "auth.login.email": "Correo electrónico",
  "auth.login.password": "Contraseña",
  "auth.login.login": "Iniciar sesión",
  "auth.login.registerLabel": "¿Nuevo en Visito?",
  "auth.login.register": "Crear una cuenta",
  "auth.login.forgotPassword": "Click aquí",
  "auth.login.forgotLabel": "¿Olvidó su contraseña?",
  "auth.register.title": "Comienza gratis",
  "auth.register.company": "Nombre de la empresa",
  "auth.register.web": "Sitio web",
  "auth.register.email": "Correo electrónico",
  "auth.register.password": "Contraseña",
  "auth.register.register": "Registrarse",
  "auth.register.loginLabel": "¿Ya tienes una cuenta?",
  "auth.register.login": "Iniciar sesión",
  "auth.register.loginTerms": "Términos de Servicio",
  "auth.register.loginPrivacy": "Política de Privacidad",
  "auth.register.continuing": "Al continuar, aceptas nuestros",
  "auth.register.titleCloudbeds": "Crea tu cuenta de Visito",
  "auth.registerUser.title": "Configura tu cuenta",
  "auth.registerUser.subtitle":
    "Por favor, establece tu contraseña para comenzar.",

  "auth.forgotPassword.title": "Restablecer contraseña",
  "auth.forgotPassword.email": "Email",
  "auth.forgotPassword.submit": "Enviar",
  "auth.forgotPassword.success":
    "El enlace para restablecer tu contraseña se ha enviado a su email",
  "auth.forgotPassword.error":
    "Email no encontrado. Por favor, verifica e intenta de nuevo.",
  "auth.forgotPassword.description":
    "Ingresa la dirección de correo electrónico asociada a tu cuenta y te enviaremos un enlace para restablecer tu contraseña",
  "auth.resetPassword.title": "Restablecer tu contraseña",
  "auth.resetPassword.password": "Nueva contraseña",
  "auth.resetPassword.cancel": "Cancelar",
  "auth.resetPassword.submit": "Restablecer",
  "auth.resetPassword.success":
    "Tu contraseña ha sido restablecida con éxito. Ahora puedes iniciar sesión en tu cuenta usando tu nueva contraseña.",
  "auth.resetPassword.description": "Elige una nueva contraseña abajo.",
  "auth.userInvite.success": "Invitación aceptada correctamente",

  "contactDetail.title": "Perfil de contacto",
  "contactDetail.actions": "Acciones",
  "contactDetail.bookings": "Reservas",
  "contactDetail.followers": "seguidores",
  "chats.newMessageInput": "Ingrese el mensaje...",
  "chats.searchContact": "Buscar contacto",
  "chats.aiAuto": "Respuestas automáticas de IA",
  "chats.markAsOpen": "Marcar como revisión",
  "chats.markAsClose": "Marcar como resuelto",
  "chats.all": "Todos",
  "chats.accounts": "Cuentas",
  "chats.manual": "Manual",
  "chats.blocked": "Bloqueado",
  "chats.reply": "Responder",
  "chats.isGuest": "Huésped",
  "chats.isBook": "Reserva Ofrecida",
  "chats.isManual": "Conversación Manual",
  "chats.isConversion": "Conversión",
  "chats.unblockContact": "Desbloquear Contacto",
  "chats.blockContact": "Bloquear Contacto",
  "chats.contactOptedOut": "El contacto se ha dado de baja",
  "chats.filter": "Filtrar",
  "chats.escalated": "Revisión",
  "chats.emptyStateTitle": "Aún no hay conversaciones",
  "chats.emptyStateSubtitle": "Tu lista de chat espera tu primer mensaje",
  "chats.emptyStateEscTitle": "No hay conversaciones en revisión",
  "chats.emptyStateEscSubtitle": "¡Parece que todo está en orden!",

  "chats.emptyWelcomeTitle": "No hay conversación seleccionada",
  "chats.emptyWelcomeSubtitle":
    "Elige una conversación de la izquierda para ver sus mensajes.",

  "interactive.call.summary": "Resumen de la llamada",
  "interactive.call.active": "Llamada activa",
  "integrations.title": "Integraciones",
  "integrations.propertiesListTitle": "Propiedades",
  "integrations.noProperties": "No se encontraron propiedades",
  "integrations.acceptCloudbedsNewIntegration":
    "Por favor, acepte la nueva integración entrante de Cloudbeds",
  "integrations.completedCloudbedsNewIntegration":
    "Has aceptado con éxito la integración con Cloudbeds",
  "integrations.completedDbIntegration": "Integración añadida con éxito",

  "integrations.fmName": "Messenger",
  "integrations.waName": "WhatsApp",
  "integrations.cbName": "Cloudbeds",
  "integrations.igName": "Instagram",
  "integrations.smName": "SiteMinder",
  "integrations.gsName": "Guesty",
  "integrations.miraiName": "Mirai",
  "integrations.voiceName": "Voz",

  "integrations.manualReplies": "La automatización por IA está en pausa.",
  "integrations.autoReplies": "La automatización por IA está activa.",

  "integrations.pmsActive": "El acceso está activo.",
  "integrations.pmsPaused": "El acceso está en pausa.",

  "integrations.igReconnect": "Agrega o quita cuentas",
  "integrations.waReconnect": "Agrega o quita numeros",
  "integrations.fmReconnect": "Agrega o quita páginas",
  "integrations.voiceReconnect": "Agrega or quita números",

  "integrations.cbReconnect": "Agrega nuevas propiedades",
  "integrations.smReconnect": "Agrega nuevas propiedades",
  "integrations.gsReconnect": "Agrega nuevas propiedades",

  "integrations.smAdd": "Agrega una nueva propiedad de SiteMinder",
  "integrations.gsAdd": "Agrega una nueva propiedad de Guesty",
  "integrations.voiceAdd": "Agrega un nuevo número telefónico de Twilio",

  "integrations.nameDb": "Nombre",
  "integrations.refreshTokenDb": "API Secret",
  "integrations.clientIdDb": "API Client ID",
  "integrations.propertyIdDb": "ID de la Propiedad",
  "integrations.widgetIdDb": "ID del widget del motor de reservas",
  "integrations.publisherDb": "Publicador",
  "integrations.currencyDb": "Moneda",
  "integrations.countryCodeDb": "Selecciona el país",
  "integrations.timezoneDb": "Zona Horaria",
  "integrations.bookingEngineDb": "Motor de Reservas",
  "integrations.phoneNumberDb": "Número de teléfono",

  "integrations.nameDbPlaceholder": "Hotel Santa Fe",
  "integrations.propertyIdDbPlaceholder": "28093",
  "integrations.widgetIdDbPlaceholder": "santafedirect",
  "integrations.publisherDbPlaceholder": "littlehotelier",
  "integrations.currencyDbPlaceholder": "MXN",
  "integrations.countryCodeDbPlaceholder": "CO",
  "integrations.phoneNumberDbPlaceholder": "5585655477",

  "integrations.pageTitle": "Integraciones",
  "integrations.pageDescription":
    "Conecta tu asistente a los principales canales de comunicación y fuentes de datos",

  "integrations.fmDescription": "Conecta tus páginas de Facebook",
  "integrations.waDescription": "Conecta tu número de WhatsApp",
  "integrations.cbDescription": "Conecta tus propiedades de Cloudbeds",
  "integrations.igDescription": "Conecta tus cuentas de Instagram",
  "integrations.smDescription": "Conecta tus propiedades de SiteMinder",
  "integrations.gsDescription": "Conecta tus propiedades de Guesty",
  "integrations.miraiDescription": "Conecta tu motor de reservas de Mirai",
  "integrations.voiceDescription": "Conecta tu número de teléfono",

  "teams.title": "Gestión de Equipos",
  "teams.subTitle": "Gestiona el acceso de los miembros del equipo",
  "teams.inviteMember": "Invitar a un nuevo miembro del equipo",
  "teams.selectorLabel": "Rol",
  "teams.rol.admin": "Administrador",
  "teams.rol.user": "Usuario",
  "teams.emailLabel": "Email",
  "teams.button": "Invitar",
  "teams.message.success":
    "Un enlace de invitación ha sido enviado a su correo.",
  "teams.passwordLabel": "Contraseña",
  "teams.buttonAccept": "Aceptar",
  "teams.inviteInfo": "Aceptar invitación a Equipos",
  "teams.modalMessageTitle.success": "Invitación exitosa",
  "teams.modalMessageTitle.error": "Error al enviar invitación",
  "teams.modal.button": "Cerrar",
  "invitedList.Members": "Lista de miembros del equipo",
  "invitedList.emailLabel": "Email",
  "invitedList.roleLabel": "Rol",
  "invitedList.dateLabel": "Se unió",
  "invitedList.title.deleteSuccess": "Eliminar usuario",
  "invitedList.message.deleteSuccess": "Usuario eliminado correctamente",
  "invitedList.message.deleteError": "Error al eliminar el usuario",
  "payment.title": "Facturación",
  "payment.subTitle":
    "Administra tus métodos de pago, suscripciones y detalles de facturación.",
  "payment.monthly": "Mensual",
  "payment.annual": "Anual",
  "payment.currentPlan": "Plan Actual",
  "payment.managePlan": "Administrar Plan",
  "payment.billingEmail": "Email de Facturación",
  "language.title": "Idioma",
  "language.subTitle":
    "Seleccione el idioma al que desea cambiar la plataforma",
  "language.selectorLabel": "Seleccionar Idioma",
  "language.english": "English",
  "language.spanish": "Español",
  "language.portuguese": "Português",
  "language.languages": "Idiomas",
  "language.en_US": "Inglés",
  "language.es_MX": "Español",
  "language.pt_BR": "Portugués",

  "home.statsTitle": "Estadísticas de los últimos 30 días",
  "home.pageTitle": "Hola",
  "home.pageDescription": "Bienvenido a tu dashboard de Visito",

  "home.skip": "Saltar introducción",

  "home.onboardingTitle": "Empezar",
  "home.onboardingDescription":
    "Completa los siguientes pasos para configurar tu cuenta",

  "home.title_location": "Conecta una propiedad",
  "home.description_location": "Integra tu propiedad con Visito",

  "home.title_channel": "Conecta un canal",
  "home.description_channel":
    "Conecta un canal para empezar a recibir mensajes",

  "home.title_account": "Crea tu cuenta",
  "home.description_account":
    "Administra todas las comunicaciones en una sola bandeja de entrada de Visito",
  "home.title_wa": "Conectar WhatsApp",
  "home.description_wa":
    "Alcanza a más de 2 mil millones de clientes en todo el mundo",

  "home.title_fm": "Conectar Facebook Messenger",
  "home.description_fm":
    "Impulsa el soporte y las reservas con Facebook Messenger",

  "home.title_ig": "Conectar Instagram",
  "home.description_ig":
    "Conecta la bandeja de entrada de tu cuenta de Instagram para empezar a recibir mensajes",

  "home.title_ai": "Configura las fuentes de datos de la IA",
  "home.description_ai": "Programa tu agente de IA con la información correcta",

  "home.pendingItems": "acciones pendientes",

  "home.uniqueContacts": "Contactos Únicos",
  "home.totalMessages": "Mensajes Totales",
  "home.percentageAuto": "Resolución IA (%)",
  "home.noStats":
    "Parece que aún no hay datos. Por favor, vuelva más tarde o ajuste sus filtros.",

  "outbound.event": "Evento",
  "outbound.pageTitle": "Eventos",
  "outbound.pageDescription":
    "Mejora la experiencia del huésped con mensajes automatizados durante todo el proceso de reserva",
  "outbound.viewEvent": "Ver evento",
  "outboud.messagesSent": "Mensajes enviados en los últimos 30 días",
  "outbound.reservation":
    "Después de que un huésped complete su reserva, el huésped recibe un mensaje de confirmación con detalles de su reserva.",
  "outbound.time_reservation": "Enviado inmediatamente",
  "outbound.arrival":
    "Antes de la llegada, el huésped recibe un mensaje con información útil sobre su estancia.",
  "outbound.time_arrival": "2 días antes de la llegada",
  "outbound.stay":
    "Después de que el huésped haga el check-in, el huésped recibe un mensaje con instrucciones especiales e información útil.",
  "outbound.time_stay": "Enviado inmediatamente después de la llegada",
  "outbound.after_departure":
    "Después de que el huésped haga el check-out, el huésped recibe un mensaje de agradecimiento junto con una solicitud de retroalimentación.",
  "outbound.time_after_departure": "1 día después de la salida",
  "outbound.inactiveTitle":
    "¿Estás seguro de que deseas desactivar este evento?",
  "outbound.inactiveDesc":
    "Desactivar este evento provocará que no se envíen estos mensajes.",
  "outbound.disabling": "Desactivando el evento...",
  "outbound.cardTitle": "Eventos de Salida",
  "outbound.recentActivity": "Actividad Reciente",
  "outbound.noActivity": "Sin actividad",
  "outbound.missingChannel":
    "¡Ups! Aún no has conectado un número de WhatsApp a tu cuenta.",
  "outbound.missingChannelDesc":
    "Conecta tu número de WhatsApp para interactuar con tus huéspedes durante las reservas, las llegadas y después de su partida.",
  "outbound.missingChannelCTA": "Haga clic aquí para conectar",
  "outbound.modifyTemplate": "Editar plantilla",
  "outbound.templateTitle": "Editar Plantilla: {template}",
  "outbound.templateLastUpdate":
    "Última modificación: {date} (Modificado {count, plural, one {# vez} other {# veces}} en 30 días)",
  "outbound.templateLangDesc":
    "Modifique el mensaje que desea enviar a los huéspedes en {lang}",
  "outbound.templatePreview": "Vista previa del mensaje",
  "outbound.templatePreviewDesc":
    "A continuación se muestra cómo se verá su mensaje",
  "outbound.templateVarsInfo":
    "Es posible que deba asegurarse que las etiquetas se ingresen correctamente. A continuación se listan para saber qué etiquetas puede usar",
  "outbound.templateVardDesc":
    "Utilice estas etiquetas en el cuerpo del mensaje",
  "outbound.templateTag": "Etiqueta",
  "outbound.templateFunction": "Función",
  "outbound.template_confirmationId": "Número de confirmación",
  "outbound.template_username": "El nombre completo del contacto",
  "outbound.template_checkin": "Fecha de entrada del huésped",
  "outbound.template_checkout": "Fecha de salida del huésped",
  "outbound.template_status_PENDING":
    "Se están revisando los nuevos cambios de la plantilla. Este proceso puede tardar hasta 24 para que se aprueben los nuevos cambios. No puedes modificar la plantilla enviada hasta que se complete la revisión.",
  "outbound.template_status_REJECTED":
    "Sus cambios han sido rechazados por el siguiente motivo: {reason}",
  "outbound.template_status_LESS_THAN_ONE_DAY":
    "Espere 24 horas desde la última actualización para editar la plantilla nuevamente",
  "outbound.templateReviewTitle":
    "¿Está seguro de que desea enviar estos nuevos cambios para su revisión?",
  "outbound.templateReviewDesc":
    "La revisión puede tardar hasta 24 horas para ser aprobado. No podrá realizar más cambios hasta que finalice la revisión.",
  "outbound.templateSend": "Sí, enviar para revisión",
  "outbound.templateSendForReview":
    "La plantilla se envió a revisión, el proceso de aprobación puede tardar hasta 24 horas",

  "onboarding.getStarted": "Comenzar",
  "onboarding.pageTite": "Introducción",
  "onboarding.stepsTitle":
    "Elige un momento para completar tu incorporación a Visito",
  "onboarding.step1":
    "Revisar la información de tu propiedad y la configuración del chat",
  "onboarding.step2":
    "Configurar tus canales de comunicación, incluyendo WhatsApp e Instagram",
  "onboarding.step3": "Resumen de las funciones y capacidades de la cuenta",
  "onboarding.step4":
    "Enviar mensajes de prueba y habilitar Visito en tus propiedades digitales",
  "onboarding.step5": "¡Responder cualquier otra pregunta que puedas tener!",

  "onboarding.businessInfo": "Detalles de tu negocio",
  "onboarding.businessInfoWorking":
    "¡Casi estamos! Esto debería tomar menos de un minuto...",
  "onboarding.businessInfoDone": "Todo listo.",
  "onboarding.businessInfoContinue":
    "Revisar la información y continuar. Siempre puedes editar esto más tarde.",
  "onboarding.channelsDone": "Todo listo!",
  "onboarding.continueToDashboard": "Continuar al dashboard",
  "onboarding.igConnect": "Conectar tu cuenta automáticamente.",
  "onboarding.igConnected": "Tu cuenta de Instagram ha sido conectada.",
  "onboarding.waConnect": "Comienza en minutos.",
  "onboarding.waConnected":
    "Tu número de teléfono de WhatsApp ha sido conectado.",
  "onboarding.channelsTitle": "Conecta Instagram o WhatsApp para empezar:",

  "newConv.Title": "Crear una nueva conversación",
  "newConv.Desc":
    "Ingrese el número de Whatsapp a continuación para crear una nueva conversación.",
  "newConv.invalidPhone": "Parece que has ingresado un número incorrecto.",
  "newConv.success": "Mensaje enviado exitosamente",
  "newConv.invalidFile": "El archivo no es válido",
};

export default lang;
