import config from "../../../config";
import { connect } from "react-redux";
import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import classNames from "classnames";
import { Location, History } from "history";
import { IconX, IconPhoneCall } from "@tabler/icons-react";

import { FormattedMessage, useIntl } from "react-intl";
import { deserialize } from "../../../utils/serialize";

import { Dropdown } from "react-bootstrap";
import {
  IonPage,
  IonContent,
  IonToggle,
  IonSearchbar,
  IonIcon,
  useIonToast,
} from "@ionic/react";
import EmptyConversationSvg from "../../../theme/images/no-conversation-selected.svg";
import UserSvg from "../../../theme/images/user.svg";
import SparksSvg from "../../../theme/images/sparks.svg";

// Icon
import { closeCircle } from "ionicons/icons";

// Validator
import { validateMessage } from "../../../utils/validators";
// Actions & Interfaces
import { setRequest } from "../../../actions/requests";

import { setSendLoading } from "../../../actions/send";
import {
  Contact,
  Settings,
  ChatInputRef,
  Filter,
  Message,
} from "../../../interfaces";

//API
import ContactsApi from "../../../api/contacts";

// Components
import ChatList from "../../../components/ChatList";
import ChatDetail from "../../../components/ChatDetail";
import ContactDetails from "../../../components/ContactDetails";
import MediaModal from "../../../components/MediaModal";
import UploadPhotoModal from "../../../components/UploadPhotoModal";
import SkeletonLoader from "../../../components/SkeletonLoader";
import CustomToggle from "../../../components/CustomToggle";
import ChatInput from "../../../components/common/chat-input";
import FileInput from "../../../components/common/file-input";
import DropdownCheckbox from "../../../components/common/dropdown-checkbox";

import Call from "../../../components/common/call";

import styles from "./styles.module.css";
import "./Chats.css";
import useContactsStore, {
  useSelectContactById,
} from "../../../store/contacts";
import { useSocketStore } from "../../../store/socket";

const NewConversation = lazy(
  () => import("../../../components/new-conversation")
);

const isDev = config.ENV === "staging" || config.ENV === "local";

const list: Contact[] = [];

interface ILayoutProps {
  dispatch: Function;
  contacts: Contact[];
  escalatedCount: number;
  send: {
    body: string;
    loading: Boolean;
  };
  location: Location;
  history: History;
  settings: Settings;
}

const Chats: React.FC<ILayoutProps> = (props: ILayoutProps) => {
  const { location, history, settings } = props;

  const { userSocketLoading: loading } = useSocketStore();

  const {
    contactList: contacts,
    escalatedCount,
    currentContact: selectedId,
    setContact: setSelectedId,
    filters,
    setFilters,
  } = useContactsStore();
  const selectedChat = useSelectContactById(selectedId);

  const [body, setBody] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [scrollEscalations, setScrollEscalations] = useState(0);
  const [results, setResults] = useState(list);
  const [infoOpen, toggleInfoOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpload, setIsOpenUpload] = useState(false);
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const [img, setImg] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [docFiles, setDocFiles] = useState<File[]>([]);
  const [isOpenDocsUpload, setIsDocsOpenUpload] = useState<boolean>(false);
  const [isOpenNewConv, setOpenNewConv] = useState<boolean>(false);
  const [replyTo, setReplyTo] = useState<Message | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const hiddenDocInput = useRef<HTMLInputElement | null>(null);
  const chatInputRef = React.useRef<ChatInputRef>(null);

  const [present] = useIonToast();
  const intl = useIntl();

  useEffect(() => {
    focusChatInput();
    setBody("");
  }, [selectedChat.contact.id]);

  useEffect(() => {
    // HAY QUE ENFOCAR EL INPUT
    if (selectedChat.messages.length) {
      const lastMessage =
        selectedChat.messages[selectedChat.messages.length - 1];
      if (lastMessage.id) {
        ContactsApi.readMessages(selectedChat.contact.id, lastMessage.id);
      }
    }
  }, [selectedChat.messages.length]);

  useEffect(() => {
    const { chat } = deserialize(location.search);
    if (chat && !selectedId) {
      setSelectedId(chat);
      setTimeout(() => props.dispatch(setRequest({ contactId: chat })), 500);
    }
  }, [location.search]);

  const sendMessage = async (content: string) => {
    props.dispatch(setSendLoading(true));
    setBody("");
    setReplyTo(null);
    const { err } = await ContactsApi.postMessages(selectedId, {
      content,
      replyTo: replyTo?.id,
    });
    props.dispatch(setSendLoading(false));
    if (err) {
      if (err.error) {
        return present({
          message: err.error?.[intl.locale],
          duration: 6000,
          color: "warning",
          position: "bottom",
        });
      }
      console.log(err);
    }
  };

  const sendPhotos = async (content: FormData) => {
    setIsLoadingUpload(true);
    const { err } = await ContactsApi.uploadMedia(selectedId, content);
    setIsLoadingUpload(false);
    if (err) {
      if (err.error) {
        return present({
          message: err.error?.[intl.locale],
          duration: 6000,
          color: "warning",
          position: "bottom",
        });
      }
    }
    setIsOpenUpload(false);
    return onHandleModalClose();
  };

  const selectChat = async (contactId: string) => {
    props.dispatch(setRequest({ contactId }));
    history.push({
      pathname: "/chats",
      search: `chat=${contactId}`,
    });
    toggleInfoOpen(true);
    setSelectedId(contactId);
  };

  const enableOrDisableContact = async (enabled: boolean) => {
    const { err } = await ContactsApi.putEnabled(selectedId, { enabled });
    if (err) {
      console.log(err);
    }
  };

  const solveEscalations = async (id: string) => {
    const { err } = await ContactsApi.putSolveEscalations(id);
    if (err) {
      console.log(err);
    }
  };

  const handleSendSubmit = async () => {
    const { err } = validateMessage(body);
    if (err) {
      return;
    }
    return sendMessage(body);
  };

  const handleInputChange = (body: string) => setBody(body);

  const handleAutoChange = (event: CustomEvent<{ checked: boolean }>) => {
    return enableOrDisableContact(event.detail.checked);
  };

  const toggleBlock = async (contact: Contact) => {
    const { id, blocked } = contact;

    const { err } = await ContactsApi.putBlocked(id, { blocked: !blocked });
    if (err) {
      console.log(err);
    }
  };

  const runConversational = async () => {
    try {
      const { err } = await ContactsApi.runConversational(selectedId);
      if (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openImage = (url: string) => {
    setImg(url);
    return setIsOpen(true);
  };

  const handleSearch = async (
    event: CustomEvent<{ value?: string | null | undefined }>
  ) => {
    if (event.detail.value) {
      const { data, err } = await ContactsApi.search(
        event.detail.value,
        settings.companies
      );
      if (data) {
        setSearchActive(true);
        setResults(
          data.map((d) => ({
            ...d,
            type: d.callId
              ? "voice"
              : d.igId
              ? "ig"
              : d.fmId
              ? "fm"
              : d.calryId
              ? "ab"
              : "wa",
          }))
        );
      }
      if (err) {
        console.log(err);
      }
    } else {
      setSearchActive(false);
    }
  };

  const openSendPhoto = () => {
    hiddenFileInput.current?.click();
  };

  const openSendDoc = () => {
    hiddenDocInput.current?.click();
  };

  const scrollToEscalation = () => {
    setScrollEscalations(scrollEscalations + 1);
  };

  const onHandleModalClose = () => {
    setIsDocsOpenUpload(() => false);
    setIsOpenUpload(() => false);

    if (hiddenFileInput?.current?.value) {
      hiddenFileInput.current.value = "";
    }
    if (hiddenDocInput.current?.value) {
      hiddenDocInput.current.value = "";
    }

    setFiles([]);
    return setDocFiles([]);
  };

  const onFilterChange = ({
    field,
    checked,
    type,
  }: {
    field: keyof Contact | string;
    checked: boolean;
    type: keyof Filter;
  }) => {
    const updatedFilter = checked
      ? {
          ...filters,
          [type]:
            type === "simple"
              ? [...filters.simple, field as keyof Contact]
              : [...filters.companies, field as string],
        }
      : {
          ...filters,
          [type]:
            type === "simple"
              ? filters.simple.filter((item) => item !== field)
              : filters.companies.filter((item) => item !== field),
        };

    setFilters(updatedFilter);
    props.dispatch(setRequest({ filter: updatedFilter }));
  };

  const onChangeFileInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "photo" | "doc"
  ) => {
    setIsLoadingUpload(false);
    const fileList = e.target.files;
    if (fileList && fileList.length > 0) {
      const files = [...fileList];

      if (type === "photo") {
        setFiles(files);
        return setIsOpenUpload(true);
      }

      setDocFiles(files);
      return setIsDocsOpenUpload(true);
    }
  };

  const createEscalation = async (contactId: string) => {
    await ContactsApi.escalateContact(contactId, {});
  };

  const focusChatInput = () => chatInputRef.current?.setInputFocus();

  return (
    <IonPage id="main-content">
      <IonContent className="ion-padding">
        <div className="page-wrapper">
          <div className="chat-wrapper-page">
            <div className="chat-list">
              {loading ? (
                <SkeletonLoader rows={8} />
              ) : (
                <>
                  <div className="chat-list-header">
                    <div className="flex justify-content-between align-items-center gap-2 px-3 pt-3 mb-0">
                      <h1 className="fs-6 fw-semibold mb-0">Inbox</h1>
                      {settings.isWaReady && (
                        <>
                          <button
                            className={`${styles.newConvBtn} fs-3 fw-semibold-1`}
                            onClick={() => setOpenNewConv(true)}
                          >
                            <FormattedMessage id="common.newConversation" />
                          </button>
                          <Suspense fallback="Loading...">
                            <NewConversation
                              isOpen={isOpenNewConv}
                              onOpenChange={setOpenNewConv}
                              settings={settings}
                            />
                          </Suspense>
                        </>
                      )}
                    </div>
                    <div className="d-flex align-items-sm-center chat-list-header-search">
                      <IonSearchbar
                        className={classNames({
                          "search-is-active": searchActive === true,
                        })}
                        debounce={100}
                        onIonInput={(ev) => handleSearch(ev)}
                        placeholder={intl.formatMessage({
                          id: "chats.searchContact",
                        })}
                        mode={`ios`}
                        onIonFocus={() => {
                          setSearchActive(true);
                        }}
                        onIonClear={() => {
                          setSearchActive(false);
                          setResults([]);
                        }}
                        onIonBlur={() => {
                          if (!results.length) {
                            setSearchActive(false);
                          }
                        }}
                      />
                      <div className="d-flex align-items-center">
                        <DropdownCheckbox
                          options={[
                            { id: "escalated" },
                            { id: "manual" },
                            { id: "isBook" },

                            ...(isDev
                              ? [{ id: "isConversion" }, { id: "isManual" }]
                              : []),
                          ]}
                          companies={settings.companies}
                          onFilterChange={onFilterChange}
                          filters={filters}
                        />
                        {filters.simple.length || filters.companies.length ? (
                          <IonIcon
                            style={{ opacity: "50%", marginLeft: "3px" }}
                            icon={closeCircle}
                            className="pointer"
                            size="small"
                            onClick={() =>
                              setFilters({ simple: [], companies: [] })
                            }
                          ></IonIcon>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        display: searchActive ? "none" : "block",
                      }}
                      className="chat-list-header-review"
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input primary"
                          type="checkbox"
                          value=""
                          id="toggleOpen"
                          checked={filters.simple.includes("escalated")}
                          onChange={(e) => {
                            setFilters(
                              e.target.checked
                                ? {
                                    simple: ["escalated"],
                                    companies: filters.companies,
                                  }
                                : { simple: [], companies: filters.companies }
                            );
                          }}
                        />

                        <label
                          htmlFor="toggleOpen"
                          className="form-check-label color-step-900 font-14 fw-semibold-1 pointer"
                        >
                          <FormattedMessage id="esc.needsReview" /> (
                          {escalatedCount})
                        </label>
                      </div>
                    </div>
                  </div>
                  <ChatList
                    mode={"desktop"}
                    filters={filters}
                    select={selectChat}
                    contacts={searchActive ? results : contacts}
                    selectedId={selectedId}
                    //changeStatus={changeStatusContact}
                    isSearch={searchActive}
                    isMulti={settings.multiAccount}
                  />
                </>
              )}
            </div>

            {loading ? null : selectedId ? (
              <div className="chat-presentation">
                <div className="chat-interface">
                  <div className="chat-interface-header">
                    <div className="navigation">
                      {selectedChat.contact.id ? (
                        <>
                          <div
                            className="chat-header-contact-info"
                            onClick={() => toggleInfoOpen(!infoOpen)}
                          >
                            <div
                              className={classNames("user-platform", {
                                "instagram-logo":
                                  selectedChat.contact.type === "ig",
                                "whatsapp-logo":
                                  selectedChat.contact.type === "wa",
                                "messenger-logo":
                                  selectedChat.contact.type === "fm",
                                "airbnb-logo":
                                  selectedChat.contact.type === "ab",
                                "web-logo":
                                  selectedChat.contact.type === "web" ||
                                  selectedChat.contact.type === "play",
                                "voice-logo":
                                  selectedChat.contact.type === "voice",
                              })}
                            ></div>

                            {settings.multiAccount &&
                              selectedChat.contact.companies.map((c) => (
                                <div className="user-company mr-1" key={c.id}>
                                  <span className="badge text-bg-light">
                                    {c.name}
                                  </span>
                                </div>
                              ))}

                            <div className="user-info">
                              {selectedChat.contact.name}
                            </div>
                            <div className="user-info-button btn btn-xs btn-visito-light btn-flex ml-2">
                              <img src={UserSvg} width={13} alt="" />
                            </div>
                            {selectedChat.contact.escalated ? (
                              <span className="badge text-bg-warning">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="fw-semibold-1">
                                    <FormattedMessage id="esc.needsReview" />
                                  </div>
                                </div>
                              </span>
                            ) : null}
                          </div>
                          <div className="contact-tools">
                            <IonToggle
                              checked={selectedChat.contact.enabled}
                              onIonChange={handleAutoChange}
                              mode={"ios"}
                              style={{ width: "max-content" }}
                              className="visito-toggle-conversation ios"
                            >
                              <img
                                src={SparksSvg}
                                width={23}
                                style={{ marginRight: "5px" }}
                                alt=""
                              ></img>
                              <FormattedMessage id="chats.aiAuto" />
                            </IonToggle>

                            {settings.isVoiceReady && isDev ? (
                              <div className="ml-1">
                                <Call
                                  displayName={`${selectedChat.contact.name} ${selectedChat.contact.phoneNumber}`}
                                  className="btn btn-visito-light btn-xs btn-flex"
                                  icon={
                                    <>
                                      <IconPhoneCall size={17} />
                                      <FormattedMessage id="aiSettingsCall" />
                                    </>
                                  }
                                  onCall={() => console.log("calling")}
                                  onHangUp={() => console.log("hangup")}
                                  contactId={selectedChat.contact.id}
                                />
                              </div>
                            ) : null}
                            {
                              <Dropdown style={{ marginLeft: "12px" }}>
                                <Dropdown.Toggle
                                  id="dropdown-custom-components"
                                  as={CustomToggle}
                                  title="options"
                                >
                                  Custom toggle
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      selectedChat.contact.escalated
                                        ? solveEscalations(
                                            selectedChat.contact.id
                                          )
                                        : createEscalation(
                                            selectedChat.contact.id
                                          )
                                    }
                                    eventKey="1"
                                  >
                                    {selectedChat.contact.escalated ? (
                                      <FormattedMessage id="chats.markAsClose" />
                                    ) : (
                                      <FormattedMessage id="chats.markAsOpen" />
                                    )}
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    onClick={() =>
                                      toggleBlock(selectedChat.contact)
                                    }
                                    eventKey="2"
                                  >
                                    <FormattedMessage
                                      id={
                                        selectedChat.contact.blocked
                                          ? `chats.unblockContact`
                                          : `chats.blockContact`
                                      }
                                    />
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            }
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  {selectedChat.contact.escalated ? (
                    <div className="chat-interface-header text-bg-warning-subtle">
                      <div className="navigation">
                        <div className="escalation-info color-dark">
                          <div className="color-step-900 fs-2 fw-semibold-1">
                            <span style={{ textTransform: "capitalize" }}>
                              <FormattedMessage id="esc.escalation" />:{" "}
                              {selectedChat.escalations[0].type.replace(
                                /_/g,
                                " "
                              )}{" "}
                            </span>
                            {!["manual"].includes(
                              selectedChat.escalations[0].type
                            ) ? (
                              <a
                                className="color-visito-2 pointer"
                                onClick={scrollToEscalation}
                              >
                                <FormattedMessage id="esc.clickToView" />
                              </a>
                            ) : null}
                          </div>
                          <div className="fs-1 color-step-900 m0">
                            {selectedChat.escalations[0].details}
                          </div>
                        </div>
                        <div className="escalation-resolution">
                          <button
                            className="btn btn-visito-primary btn-sm"
                            onClick={() =>
                              solveEscalations(selectedChat.contact.id)
                            }
                          >
                            <FormattedMessage id="esc.solve" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="chat-interface-body">
                    <ChatDetail
                      messages={selectedChat.messages}
                      openImage={openImage}
                      contactId={selectedChat.contact.id}
                      mode={"desktop"}
                      scrollEscalations={scrollEscalations}
                      replyTo={({ message }: { message: Message }) => {
                        setReplyTo(message);
                        focusChatInput();
                      }}
                    />
                  </div>
                  <div className="chat-interface-footer">
                    <FileInput
                      ref={hiddenFileInput}
                      accept="image/png, image/jpeg"
                      multiple
                      onChange={(e) => onChangeFileInput(e, "photo")}
                      style={{ display: "none" }}
                    />
                    <FileInput
                      ref={hiddenDocInput}
                      accept="application/pdf, .pdf"
                      onChange={(e) => onChangeFileInput(e, "doc")}
                      style={{ display: "none" }}
                    />
                    <ChatInput
                      ref={chatInputRef}
                      id="send-body"
                      value={body}
                      placeholder={intl.formatMessage({
                        id: selectedChat.contact.blocked
                          ? "chats.blocked"
                          : "chats.newMessageInput",
                      })}
                      enableWaActions={selectedChat.contact.type === "wa"}
                      onInputChange={handleInputChange}
                      onOpenPhotoInput={openSendPhoto}
                      onOpenDocInput={openSendDoc}
                      onRunConversational={runConversational}
                      onBeforeSubmit={handleSendSubmit}
                      disabled={selectedChat.contact.blocked}
                      replyTo={replyTo}
                      cleanReplyTo={() => setReplyTo(null)}
                    />
                  </div>
                </div>
                <div
                  className={classNames("chat-tools", {
                    open: infoOpen === true,
                  })}
                >
                  <div className="chat-tools-header">
                    <div
                      onClick={() => toggleInfoOpen(false)}
                      className="chat-tools-close"
                    >
                      <IconX />
                    </div>
                    <div>Info</div>
                  </div>
                  <div className="chat-tools-body">
                    <ContactDetails
                      bookings={selectedChat.bookings}
                      contact={selectedChat.contact}
                      createEscalation={createEscalation}
                      solveEscalations={solveEscalations}
                      toggleBlock={toggleBlock}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="chat-welcome">
                <div className="welcome-content text-center">
                  <div className="row justify-content-center">
                    <div className="mb-3">
                      <img alt="" src={EmptyConversationSvg}></img>
                    </div>
                    <span className="color-step-900 font-14 fw-semibold-1">
                      <FormattedMessage id="chats.emptyWelcomeTitle" />
                    </span>
                    <span className="color-step-600 font-12">
                      <FormattedMessage id="chats.emptyWelcomeSubtitle" />
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <MediaModal isOpen={isOpen} img={img} setIsOpen={setIsOpen} />
        <UploadPhotoModal
          show={isOpenUpload || isOpenDocsUpload}
          sending={isLoadingUpload}
          handleClose={onHandleModalClose}
          files={isOpenDocsUpload ? docFiles : files}
          upload={sendPhotos}
          mode={`desktop`}
          areDocs={isOpenDocsUpload}
        />
      </IonContent>
    </IonPage>
  );
};

export default connect((props: any) => ({
  send: props.send,
  settings: props.app.settings,
}))(Chats);
